const ApiService    = require("/Users/patrickbittner/plentymarkets/5.0.31/plugin-ceres/resources/js/src/app/services/ApiService.js");

import { isDefined } from "/Users/patrickbittner/plentymarkets/5.0.31/plugin-ceres/resources/js/src/app/helper/utils";

import ValidationService from "/Users/patrickbittner/plentymarkets/5.0.31/plugin-ceres/resources/js/src/app/services/ValidationService";
const ModalService = require("/Users/patrickbittner/plentymarkets/5.0.31/plugin-ceres/resources/js/src/app/services/ModalService");
const NotificationService = require("/Users/patrickbittner/plentymarkets/5.0.31/plugin-ceres/resources/js/src/app/services/NotificationService");

import TranslationService from "/Users/patrickbittner/plentymarkets/5.0.31/plugin-ceres/resources/js/src/app/services/TranslationService";

import AddressInputGroup from "/Users/patrickbittner/plentymarkets/5.0.31/plugin-ceres/resources/js/src/app/components/customer/AddressInputGroup.vue";
import { ButtonSizePropertyMixin } from "/Users/patrickbittner/plentymarkets/5.0.31/plugin-ceres/resources/js/src/app/mixins/buttonSizeProperty.mixin";

// noinspection JSAnnotator
Vue.component("egp-resend-order", {
    template: "#vue-egp-resend-order",
    name: "egp-resend-order",
    components: {
        AddressInputGroup
    },
    mixins: [ButtonSizePropertyMixin],

    props: {
        addressData: {
            type: Object,
            default()
            {
                return {};
            }
        },
        optionalAddressFields: {
            type: Object,
            default: () =>
            {
                return {};
            }
        },
        requiredAddressFields: {
            type: Object,
            default: () =>
            {
                return {};
            }
        },
        addressOptionTypeFieldMap:
            {
                1: "vatNumber",
                4: "telephone",
                6: "postNumber",
                9: "birthday",
                11: "title",
                12: "contactPerson"
            },

        orderId:{
            type: String,
            required: true
        },
        orderAccessKey:{
            type: String,
            required: true
        }
    },

    data()
    {
        return {
            isDisabled: false,
            googleRecaptchaApiKey: App.config.global.googleRecaptchaApiKey,
            honeypot: "",
            addressToEdit: {
                gender: "",
                countryId : 1
            },
            addressModal: {},
            modalType: "update",
            addressType: "2",
            headline: "",
            waiting: false
        };
    },

    mounted()
    {
        this.$nextTick(() =>
        {
            this.$store.commit("setParcelBoxAvailability", true);
            this.$store.commit("setPostOfficeAvailability", true);

            this.addressModal = ModalService.findModal(this.$refs.resendOrderModal);
        });
    },

    created()
    {

    },

    methods: {

        validate()
        {
            this.waiting = true;
            ValidationService.validate(this.$refs.addressForm)
                .done(() =>
                {
                    this.resendOrder();
                })
                .fail(invalidFields =>
                {
                    this.waiting = false;
                    const fieldNames = [];

                    for (const field of invalidFields)
                    {
                        let fieldName = field.lastElementChild.innerHTML.trim();

                        fieldName = fieldName.slice(-1) === "*" ? fieldName.slice(0, fieldName.length - 1) : fieldName;
                        fieldNames.push(fieldName);
                    }

                    ValidationService.markInvalidFields(invalidFields, "error");
                    NotificationService.error(
                        TranslationService.translate("Ceres::Template.checkoutCheckAddressFormFields", { fields: fieldNames.join(", ") })
                    );
                });
        },

        resendOrder()
        {
            ApiService.post("/rest/paketruecklaeufer/resend/", {
                orderId: this.orderId,
                orderAccessKey: this.orderAccessKey,
                address: this.addressToEdit
            }).done(response =>
            {
                window.location.href = "/confirmation/"+response.warrantyOrder.id+"/"+response.warrantyOrderAccessKey;
            })
                .fail((error) =>
                {
                    NotificationService.error(error.error).closeAfter(10000);
                    this.waiting = false;
                });
        },

        showEditModal()
        {
            this.modalType = "update";
            this.addressToEdit = this.getAddressToEdit(this.addressData);

            if (isDefined(this.addressToEdit.address1) && (this.addressToEdit.address1 === "PACKSTATION" || this.addressToEdit.address1 === "POSTFILIALE") && this.$store.getters.isParcelOrOfficeAvailable)
            {
                this.addressToEdit.showPickupStation = true;
            }

            this.updateHeadline();
            ValidationService.unmarkAllFields($(this.$refs.addressModal));
            this.addressModal.show();
        },

        closeAddressModal()
        {
            this.addressModal.hide();
        },

        getAddressToEdit(address)
        {
            // Creates a tmp address to prevent unwanted two-way binding
            const addressToEdit = JSON.parse(JSON.stringify(address));

            if (addressToEdit.options)
            {
                for (const option of addressToEdit.options)
                {
                    if (typeof(this.addressOptionTypeFieldMap && this.addressOptionTypeFieldMap[option.typeId]) !== "undefined")
                    {
                        const optionName = this.addressOptionTypeFieldMap[option.typeId] || null;

                        addressToEdit[optionName] = option.value || null;
                    }
                }
            }

            return addressToEdit;
        },

        setAddressToEditField({ field, value })
        {
            this.addressToEdit[field] = value;
            this.addressToEdit = Object.assign({}, this.addressToEdit);
        },

        updateHeadline()
        {
            let headline;

            if (this.modalType === "initial")
            {
                headline = TranslationService.translate("Ceres::Template.addressInvoiceAddressInitial");
            }
            else if (this.addressType === "2")
            {
                if (this.modalType === "update")
                {
                    headline = TranslationService.translate("Ceres::Template.addressShippingAddressEdit");
                }
                else if (this.modalType === "create")
                {
                    headline = TranslationService.translate("Ceres::Template.addressShippingAddressCreate");
                }
                else
                {
                    headline = TranslationService.translate("Ceres::Template.addressShippingAddressDelete");
                }
            }
            else if (this.modalType === "update")
            {
                headline = TranslationService.translate("Ceres::Template.addressInvoiceAddressEdit");
            }
            else if (this.modalType === "create")
            {
                headline = TranslationService.translate("Ceres::Template.addressInvoiceAddressCreate");
            }
            else
            {
                headline = TranslationService.translate("Ceres::Template.addressInvoiceAddressDelete");
            }

            this.headline = headline;
        }
    },

    watch: {
        isSalutationEnabled(newVal)
        {
            if (!newVal)
            {
                delete this.addressToEdit.gender;
            }
        }
    },

    computed: {
        isSalutationEnabled()
        {
            const countryId = parseInt(this.addressToEdit.countryId) || 1;
            const addressKey = parseInt(this.addressType) === 1 ? "billing_address" : "delivery_address";
            const countryKey = countryId === 12 ? "gb" : "de";

            return this.optionalAddressFields[countryKey].includes(`${addressKey}.salutation`);
        }
    }
});

// noinspection JSAnnotator
Vue.component("egp-change-address", {
    template: "#vue-change-address",
    name: "egp-change-address",
    components: {
        AddressInputGroup
    },
    mixins: [ButtonSizePropertyMixin],

    props: {
        addressData: {
            type: Object,
            default()
            {
                return {};
            }
        },
        optionalAddressFields: {
            type: Object,
            default: () =>
            {
                return {};
            }
        },
        requiredAddressFields: {
            type: Object,
            default: () =>
            {
                return {};
            }
        },
        addressOptionTypeFieldMap:
            {
                1: "vatNumber",
                4: "telephone",
                6: "postNumber",
                9: "birthday",
                11: "title",
                12: "contactPerson"
            },
        orderId:{
            type: String,
            required: true
        },
        orderAccessKey:{
            type: String,
            required: true
        }
    },

    data()
    {
        return {
            isDisabled: false,
            googleRecaptchaApiKey: App.config.global.googleRecaptchaApiKey,
            honeypot: "",
            addressToEdit: {
                gender: "",
                countryId : 1
            },
            addressModal: {},
            modalType: "update",
            addressType: "2",
            headline: "",
            waiting: false
        };
    },

    mounted()
    {
        this.$nextTick(() =>
        {
            this.$store.commit("setParcelBoxAvailability", true);
            this.$store.commit("setPostOfficeAvailability", true);

            this.addressModal = ModalService.findModal(this.$refs.changeAddressModal);
        });
    },

    created()
    {

    },

    methods: {

        validate()
        {
            this.waiting = true;
            ValidationService.validate(this.$refs.addressForm)
                .done(() =>
                {
                    this.changeAddress();
                })
                .fail(invalidFields =>
                {
                    this.waiting = false;
                    const fieldNames = [];

                    for (const field of invalidFields)
                    {
                        let fieldName = field.lastElementChild.innerHTML.trim();

                        fieldName = fieldName.slice(-1) === "*" ? fieldName.slice(0, fieldName.length - 1) : fieldName;
                        fieldNames.push(fieldName);
                    }

                    ValidationService.markInvalidFields(invalidFields, "error");
                    NotificationService.error(
                        TranslationService.translate("Ceres::Template.checkoutCheckAddressFormFields", { fields: fieldNames.join(", ") })
                    );
                });
        },

        changeAddress()
        {

            ApiService.post("/rest/paketruecklaeufer/change-address/", {
                orderId: this.orderId,
                orderAccessKey: this.orderAccessKey,
                address: this.addressToEdit
            }).done(response =>
            {
                window.location.href = "/confirmation/"+response.warrantyOrder.id+"/"+response.warrantyOrderAccessKey;
            }).fail((error) =>
            {
                NotificationService.error(error.error).closeAfter(10000);
                this.waiting = false;
            });

        },

        showEditModal()
        {
            this.modalType = "update";
            this.addressToEdit = this.getAddressToEdit(this.addressData);

            if (isDefined(this.addressToEdit.address1) && (this.addressToEdit.address1 === "PACKSTATION" || this.addressToEdit.address1 === "POSTFILIALE") && this.$store.getters.isParcelOrOfficeAvailable)
            {
                this.addressToEdit.showPickupStation = true;
            }

            this.updateHeadline();
            ValidationService.unmarkAllFields($(this.$refs.addressModal));
            this.addressModal.show();
        },

        closeAddressModal()
        {
            this.addressModal.hide();
        },

        getAddressToEdit(address)
        {
            // Creates a tmp address to prevent unwanted two-way binding
            const addressToEdit = JSON.parse(JSON.stringify(address));

            if (addressToEdit.options)
            {
                for (const option of addressToEdit.options)
                {
                    if (typeof(this.addressOptionTypeFieldMap && this.addressOptionTypeFieldMap[option.typeId]) !== "undefined")
                    {
                        const optionName = this.addressOptionTypeFieldMap[option.typeId] || null;

                        addressToEdit[optionName] = option.value || null;
                    }
                }
            }

            return addressToEdit;
        },

        setAddressToEditField({ field, value })
        {
            this.addressToEdit[field] = value;
            this.addressToEdit = Object.assign({}, this.addressToEdit);
        },

        updateHeadline()
        {
            let headline;

            if (this.modalType === "initial")
            {
                headline = TranslationService.translate("Ceres::Template.addressInvoiceAddressInitial");
            }
            else if (this.addressType === "2")
            {
                if (this.modalType === "update")
                {
                    headline = TranslationService.translate("Ceres::Template.addressShippingAddressEdit");
                }
                else if (this.modalType === "create")
                {
                    headline = TranslationService.translate("Ceres::Template.addressShippingAddressCreate");
                }
                else
                {
                    headline = TranslationService.translate("Ceres::Template.addressShippingAddressDelete");
                }
            }
            else if (this.modalType === "update")
            {
                headline = TranslationService.translate("Ceres::Template.addressInvoiceAddressEdit");
            }
            else if (this.modalType === "create")
            {
                headline = TranslationService.translate("Ceres::Template.addressInvoiceAddressCreate");
            }
            else
            {
                headline = TranslationService.translate("Ceres::Template.addressInvoiceAddressDelete");
            }

            this.headline = headline;
        }
    },

    watch: {
        isSalutationEnabled(newVal)
        {
            if (!newVal)
            {
                delete this.addressToEdit.gender;
            }
        }
    },

    computed: {
        isSalutationEnabled()
        {
            const countryId = parseInt(this.addressToEdit.countryId) || 1;
            const addressKey = parseInt(this.addressType) === 1 ? "billing_address" : "delivery_address";
            const countryKey = countryId === 12 ? "gb" : "de";

            return this.optionalAddressFields[countryKey].includes(`${addressKey}.salutation`);
        }
    }
});
