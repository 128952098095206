var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      staticClass: "custom-select",
      attrs: { "data-testing": "salutation-select", "data-autofocus": "" },
      domProps: { value: _vm.addressData.gender },
      on: {
        change: function($event) {
          return _vm.emitInputEvent($event.target.value)
        }
      }
    },
    _vm._l(_vm.currentSalutation, function(salutation, index) {
      return _c(
        "option",
        {
          key: index,
          domProps: {
            value: salutation.key,
            selected:
              _vm.addressData.gender === salutation.key &&
              _vm.checkGenderCompany(salutation.key)
          }
        },
        [_vm._v("\n        " + _vm._s(salutation.name) + "\n    ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }