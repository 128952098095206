var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "input-unit",
          attrs: { "data-validate": "", "data-model": "countryId" }
        },
        [
          _c(
            "select",
            {
              staticClass: "custom-select",
              attrs: { id: "country-id-select" + _vm._uid },
              domProps: { value: _vm.selectedCountryId },
              on: {
                change: function($event) {
                  return _vm.countryChanged($event.target.value)
                }
              }
            },
            _vm._l(_vm.countryList, function(country) {
              return _c(
                "option",
                {
                  key: country.id,
                  domProps: {
                    value: country.id,
                    selected: country.id === _vm.selectedCountryId
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(country.currLangName) +
                      "\n            "
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("label", { attrs: { for: "country-id-select" + _vm._uid } }, [
            _vm._v(_vm._s(_vm.$translate("Ceres::Template.headerCountry")))
          ])
        ]
      ),
      _vm._v(" "),
      _vm.isInOptionalFields("stateId")
        ? [
            _vm.stateList && _vm.stateList.length > 0
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.isInRequiredFields("stateId"),
                        expression: "isInRequiredFields('stateId')"
                      }
                    ],
                    staticClass: "input-unit",
                    attrs: { "data-model": "stateId" }
                  },
                  [
                    _c(
                      "select",
                      {
                        staticClass: "custom-select",
                        attrs: { id: "state-id-select" + _vm._uid },
                        domProps: { value: _vm.selectedStateId },
                        on: {
                          change: function($event) {
                            return _vm.stateChanged($event.target.value)
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            domProps: { selected: _vm.selectedStateId === null }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$translate(
                                  "Ceres::Template.addressPleaseSelect"
                                )
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.stateList, function(state) {
                          return _c(
                            "option",
                            {
                              key: state.id,
                              domProps: {
                                value: state.id,
                                selected: state.id === _vm.selectedStateId
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(state.name) +
                                  "\n                "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { for: "state-id-select" + _vm._uid } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.transformTranslation(
                              "Ceres::Template.headerState",
                              "stateId"
                            )
                          )
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }